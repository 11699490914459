var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "自编号", prop: "vehicleNo" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入车辆自编号 唯一性",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.queryParam.vehicleNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "vehicleNo", $$v)
                                  },
                                  expression: "queryParam.vehicleNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "车牌号",
                                prop: "licensePlateNum",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入车牌号",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.queryParam.licensePlateNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParam,
                                      "licensePlateNum",
                                      $$v
                                    )
                                  },
                                  expression: "queryParam.licensePlateNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.advanced
                        ? [
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "车辆大类",
                                      prop: "vehicleCategory",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择车辆大类",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.vehicleCategory,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "vehicleCategory",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryParam.vehicleCategory",
                                        },
                                      },
                                      _vm._l(
                                        _vm.vehicleCategoryOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "车辆功能",
                                      prop: "vehicleFunction",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择车辆功能",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.vehicleFunction,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "vehicleFunction",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryParam.vehicleFunction",
                                        },
                                      },
                                      _vm._l(
                                        _vm.vehicleFunctionOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "是否自动驾驶",
                                      prop: "automaticDriving",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择是否自动驾驶",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value:
                                            _vm.queryParam.automaticDriving,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "automaticDriving",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryParam.automaticDriving",
                                        },
                                      },
                                      _vm._l(
                                        _vm.automaticDrivingOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "车辆品牌",
                                      prop: "vehicleBrand",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择车辆品牌",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.vehicleBrand,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "vehicleBrand",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.vehicleBrand",
                                        },
                                      },
                                      _vm._l(
                                        _vm.vehicleBrandOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "车辆型号",
                                      prop: "vehicleModel",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入车辆型号",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.vehicleModel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "vehicleModel",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.vehicleModel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "整备质量(Kg)",
                                      prop: "curbWeight",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入整备质量(Kg)",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.curbWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "curbWeight",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.curbWeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "车辆颜色",
                                      prop: "vehicleColor",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择车辆颜色",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.vehicleColor,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "vehicleColor",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.vehicleColor",
                                        },
                                      },
                                      _vm._l(
                                        _vm.vehicleColorOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "核定载质量(Kg)",
                                      prop: "approvedLoad",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入核定载质量(Kg)",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.approvedLoad,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "approvedLoad",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.approvedLoad",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "强制报废期截止日期" } },
                                  [
                                    _c("a-range-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        format: "YYYY-MM-DD",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value:
                                          _vm.daterangeMandatoryScrapDeadlineDate,
                                        callback: function ($$v) {
                                          _vm.daterangeMandatoryScrapDeadlineDate =
                                            $$v
                                        },
                                        expression:
                                          "daterangeMandatoryScrapDeadlineDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "车架号码",
                                      prop: "frameNo",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入车架号码",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.frameNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "frameNo",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.frameNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "发动机号",
                                      prop: "engineNo",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入发动机号",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.engineNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "engineNo",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.engineNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "车辆照片",
                                      prop: "vehiclePhoto",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入车辆照片",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.vehiclePhoto,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "vehiclePhoto",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.vehiclePhoto",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "出厂日期" } },
                                  [
                                    _c("a-range-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        format: "YYYY-MM-DD",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.daterangeManufactureDate,
                                        callback: function ($$v) {
                                          _vm.daterangeManufactureDate = $$v
                                        },
                                        expression: "daterangeManufactureDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "驱动形式",
                                      prop: "driveType",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择驱动形式",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.driveType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "driveType",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.driveType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.driveTypeOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "发动机功率(KW)",
                                      prop: "enginePower",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入发动机功率(KW)",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.enginePower,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "enginePower",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.enginePower",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "电车-电池容量(度)",
                                      prop: "soc",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入电车-电池容量(度)",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.soc,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryParam, "soc", $$v)
                                        },
                                        expression: "queryParam.soc",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "发动机排量(L)",
                                      prop: "engineDisplacement",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入发动机排量(L)",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value:
                                          _vm.queryParam.engineDisplacement,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "engineDisplacement",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryParam.engineDisplacement",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "主发动机厂牌",
                                      prop: "mainEngineBrand",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入主发动机厂牌",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.mainEngineBrand,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "mainEngineBrand",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryParam.mainEngineBrand",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "主发动机型号",
                                      prop: "mainEngineNo",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入主发动机型号",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.mainEngineNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "mainEngineNo",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.mainEngineNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "副发动机厂牌",
                                      prop: "viceEngineBrand",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入副发动机厂牌",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.viceEngineBrand,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "viceEngineBrand",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "queryParam.viceEngineBrand",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "副发动机型号",
                                      prop: "viceEngineNo",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入副发动机型号",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.viceEngineNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "viceEngineNo",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.viceEngineNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "底盘厂牌",
                                      prop: "chassisBrand",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入底盘厂牌",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.chassisBrand,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "chassisBrand",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.chassisBrand",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "底盘型号",
                                      prop: "chassisNo",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入底盘型号",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.chassisNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "chassisNo",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.chassisNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "轮胎型号",
                                      prop: "tyreNo",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入轮胎型号",
                                        "allow-clear": "",
                                      },
                                      model: {
                                        value: _vm.queryParam.tyreNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "tyreNo",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.tyreNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "排放标准",
                                      prop: "emissionStandard",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择排放标准",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value:
                                            _vm.queryParam.emissionStandard,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "emissionStandard",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryParam.emissionStandard",
                                        },
                                      },
                                      _vm._l(
                                        _vm.emissionStandardOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "燃油标号",
                                      prop: "recommendFuelGrade",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择燃油标号",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value:
                                            _vm.queryParam.recommendFuelGrade,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "recommendFuelGrade",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryParam.recommendFuelGrade",
                                        },
                                      },
                                      _vm._l(
                                        _vm.recommendFuelGradeOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel) + " ")]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: { label: "是否租赁", prop: "lease" },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择是否租赁",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.lease,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "lease",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.lease",
                                        },
                                      },
                                      _vm._l(
                                        _vm.leaseOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    id: "tableQueryBtn",
                                  },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.toggleAdvanced },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.advanced ? "收起" : "展开") +
                                      " "
                                  ),
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.advanced ? "up" : "down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [
              _c(
                "div",
                { staticClass: "table-operations" },
                [
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:vehicleAccount:add"],
                          expression: "['tenant:iot:vehicleAccount:add']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.createForm.handleAdd()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v("新增 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:vehicleAccount:edit"],
                          expression: "['tenant:iot:vehicleAccount:edit']",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.single },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.createForm.handleUpdate(
                            undefined,
                            _vm.ids
                          )
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "edit" } }),
                      _vm._v("修改 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:vehicleAccount:remove"],
                          expression: "['tenant:iot:vehicleAccount:remove']",
                        },
                      ],
                      attrs: { type: "danger", disabled: _vm.multiple },
                      on: { click: _vm.handleDelete },
                    },
                    [
                      _c("a-icon", { attrs: { type: "delete" } }),
                      _vm._v("删除 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:vehicleAccount:import"],
                          expression: "['tenant:iot:vehicleAccount:import']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.importExcel.importExcelHandleOpen()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "import" } }),
                      _vm._v("导入 "),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:vehicleAccount:export"],
                          expression: "['tenant:iot:vehicleAccount:export']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleExport },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("create-form", {
            ref: "createForm",
            attrs: {
              vehicleCategoryOptions: _vm.vehicleCategoryOptions,
              vehicleFunctionOptions: _vm.vehicleFunctionOptions,
              automaticDrivingOptions: _vm.automaticDrivingOptions,
              vehicleBrandOptions: _vm.vehicleBrandOptions,
              vehicleColorOptions: _vm.vehicleColorOptions,
              driveTypeOptions: _vm.driveTypeOptions,
              emissionStandardOptions: _vm.emissionStandardOptions,
              recommendFuelGradeOptions: _vm.recommendFuelGradeOptions,
              leaseOptions: _vm.leaseOptions,
              statusOptions: _vm.statusOptions,
              deptOptions: _vm.deptOptions,
            },
            on: { ok: _vm.getList },
          }),
          _c("import-excel", { ref: "importExcel", on: { ok: _vm.getList } }),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "vehicleCategory",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.vehicleCategoryFormat(record) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.vehicleCategoryFormat(record)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "vehicleFunction",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.vehicleFunctionFormat(record) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.vehicleFunctionFormat(record)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "automaticDriving",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.automaticDrivingFormat(record)) + " "
                    ),
                  ])
                },
              },
              {
                key: "insuranceEndDate",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {
                      attrs: {
                        title: _vm.parseDateTime(
                          record.insuranceEndDate,
                          "yyyy-MM-dd"
                        ),
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.parseDateTime(
                              record.insuranceEndDate,
                              "yyyy-MM-dd"
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "vehicleBrand",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.vehicleBrandFormat(record) } },
                    [_vm._v(" " + _vm._s(_vm.vehicleBrandFormat(record)) + " ")]
                  )
                },
              },
              {
                key: "vehicleColor",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.vehicleColorFormat(record)) + " "),
                  ])
                },
              },
              {
                key: "driveType",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.driveTypeFormat(record)) + " "),
                  ])
                },
              },
              {
                key: "emissionStandard",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.emissionStandardFormat(record)) + " "
                    ),
                  ])
                },
              },
              {
                key: "lease",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.leaseFormat(record)) + " "),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      record.lease == 1
                        ? _c("a-divider", {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["tenant:iot:insurance:list"],
                                expression: "['tenant:iot:insurance:list']",
                              },
                            ],
                            attrs: { type: "vertical" },
                          })
                        : _vm._e(),
                      record.lease == 1
                        ? _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:lease:list"],
                                  expression: "['tenant:iot:lease:list']",
                                },
                              ],
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToInsurance(record)
                                },
                              },
                            },
                            [_vm._v(" 保险记录 ")]
                          )
                        : _vm._e(),
                      record.lease == 1
                        ? _c("a-divider", {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["tenant:iot:lease:list"],
                                expression: "['tenant:iot:lease:list']",
                              },
                            ],
                            attrs: { type: "vertical" },
                          })
                        : _vm._e(),
                      record.lease == 1
                        ? _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:lease:list"],
                                  expression: "['tenant:iot:lease:list']",
                                },
                              ],
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToLease(record)
                                },
                              },
                            },
                            [_vm._v(" 车辆租赁 ")]
                          )
                        : _vm._e(),
                      record.lease == 1
                        ? _c("a-divider", {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["tenant:iot:annualReview:list"],
                                expression: "['tenant:iot:annualReview:list']",
                              },
                            ],
                            attrs: { type: "vertical" },
                          })
                        : _vm._e(),
                      record.lease == 1
                        ? _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:annualReview:list"],
                                  expression:
                                    "['tenant:iot:annualReview:list']",
                                },
                              ],
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToAnnualReview(record)
                                },
                              },
                            },
                            [_vm._v(" 车辆年审 ")]
                          )
                        : _vm._e(),
                      _c("a-divider", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:iot:vehicleAccount:edit"],
                            expression: "['tenant:iot:vehicleAccount:edit']",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["tenant:iot:vehicleAccount:edit"],
                              expression: "['tenant:iot:vehicleAccount:edit']",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.$refs.createForm.handleUpdate(
                                record,
                                undefined
                              )
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "edit" } }),
                          _vm._v("修改 "),
                        ],
                        1
                      ),
                      _c("a-divider", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:iot:vehicleAccount:remove"],
                            expression: "['tenant:iot:vehicleAccount:remove']",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["tenant:iot:vehicleAccount:remove"],
                              expression:
                                "['tenant:iot:vehicleAccount:remove']",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(record)
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "delete" } }),
                          _vm._v("删除 "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }