<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="自编号" prop="vehicleNo">
                <a-input v-model="queryParam.vehicleNo" placeholder="请输入车辆自编号 唯一性" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="车牌号" prop="licensePlateNum">
                <a-input v-model="queryParam.licensePlateNum" placeholder="请输入车牌号" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆大类" prop="vehicleCategory">
                  <a-select
                    placeholder="请选择车辆大类"
                    v-model="queryParam.vehicleCategory"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in vehicleCategoryOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆功能" prop="vehicleFunction">
                  <a-select
                    placeholder="请选择车辆功能"
                    v-model="queryParam.vehicleFunction"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in vehicleFunctionOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否自动驾驶" prop="automaticDriving">
                  <a-select
                    placeholder="请选择是否自动驾驶"
                    v-model="queryParam.automaticDriving"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in automaticDrivingOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆品牌" prop="vehicleBrand">
                  <a-select
                    placeholder="请选择车辆品牌"
                    v-model="queryParam.vehicleBrand"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in vehicleBrandOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆型号" prop="vehicleModel">
                  <a-input v-model="queryParam.vehicleModel" placeholder="请输入车辆型号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="整备质量(Kg)" prop="curbWeight">
                  <a-input v-model="queryParam.curbWeight" placeholder="请输入整备质量(Kg)" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆颜色" prop="vehicleColor">
                  <a-select
                    placeholder="请选择车辆颜色"
                    v-model="queryParam.vehicleColor"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in vehicleColorOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="核定载质量(Kg)" prop="approvedLoad">
                  <a-input v-model="queryParam.approvedLoad" placeholder="请输入核定载质量(Kg)" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="强制报废期截止日期">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeMandatoryScrapDeadlineDate"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="车架号码" prop="frameNo">
                  <a-input v-model="queryParam.frameNo" placeholder="请输入车架号码" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="发动机号" prop="engineNo">
                  <a-input v-model="queryParam.engineNo" placeholder="请输入发动机号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆照片" prop="vehiclePhoto">
                  <a-input v-model="queryParam.vehiclePhoto" placeholder="请输入车辆照片" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="出厂日期">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeManufactureDate"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="驱动形式" prop="driveType">
                  <a-select placeholder="请选择驱动形式" v-model="queryParam.driveType" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in driveTypeOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="发动机功率(KW)" prop="enginePower">
                  <a-input v-model="queryParam.enginePower" placeholder="请输入发动机功率(KW)" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="电车-电池容量(度)" prop="soc">
                  <a-input v-model="queryParam.soc" placeholder="请输入电车-电池容量(度)" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="发动机排量(L)" prop="engineDisplacement">
                  <a-input v-model="queryParam.engineDisplacement" placeholder="请输入发动机排量(L)" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="主发动机厂牌" prop="mainEngineBrand">
                  <a-input v-model="queryParam.mainEngineBrand" placeholder="请输入主发动机厂牌" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="主发动机型号" prop="mainEngineNo">
                  <a-input v-model="queryParam.mainEngineNo" placeholder="请输入主发动机型号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="副发动机厂牌" prop="viceEngineBrand">
                  <a-input v-model="queryParam.viceEngineBrand" placeholder="请输入副发动机厂牌" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="副发动机型号" prop="viceEngineNo">
                  <a-input v-model="queryParam.viceEngineNo" placeholder="请输入副发动机型号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="底盘厂牌" prop="chassisBrand">
                  <a-input v-model="queryParam.chassisBrand" placeholder="请输入底盘厂牌" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="底盘型号" prop="chassisNo">
                  <a-input v-model="queryParam.chassisNo" placeholder="请输入底盘型号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="轮胎型号" prop="tyreNo">
                  <a-input v-model="queryParam.tyreNo" placeholder="请输入轮胎型号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="排放标准" prop="emissionStandard">
                  <a-select
                    placeholder="请选择排放标准"
                    v-model="queryParam.emissionStandard"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in emissionStandardOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="燃油标号" prop="recommendFuelGrade">
                  <a-select
                    placeholder="请选择燃油标号"
                    v-model="queryParam.recommendFuelGrade"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option
                      v-for="(d, index) in recommendFuelGradeOptions"
                      :key="index"
                      :value="d.dictValue">{{ d.dictLabel }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否租赁" prop="lease">
                  <a-select placeholder="请选择是否租赁" v-model="queryParam.lease" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in leaseOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:vehicleAccount:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:vehicleAccount:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:iot:vehicleAccount:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button
            type="primary"
            @click="$refs.importExcel.importExcelHandleOpen()"
            v-hasPermi="['tenant:iot:vehicleAccount:import']"
          >
            <a-icon type="import" />导入
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicleAccount:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :vehicleCategoryOptions="vehicleCategoryOptions"
        :vehicleFunctionOptions="vehicleFunctionOptions"
        :automaticDrivingOptions="automaticDrivingOptions"
        :vehicleBrandOptions="vehicleBrandOptions"
        :vehicleColorOptions="vehicleColorOptions"
        :driveTypeOptions="driveTypeOptions"
        :emissionStandardOptions="emissionStandardOptions"
        :recommendFuelGradeOptions="recommendFuelGradeOptions"
        :leaseOptions="leaseOptions"
        :statusOptions="statusOptions"
        :deptOptions="deptOptions"
        @ok="getList"
      />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="vehicleCategory" slot-scope="text, record" :title="vehicleCategoryFormat(record)">
          {{ vehicleCategoryFormat(record) }}
        </span>
        <span slot="vehicleFunction" slot-scope="text, record" :title="vehicleFunctionFormat(record)">
          {{ vehicleFunctionFormat(record) }}
        </span>
        <span slot="automaticDriving" slot-scope="text, record">
          {{ automaticDrivingFormat(record) }}
        </span>
        <span
          slot="insuranceEndDate"
          slot-scope="text, record"
          :title="parseDateTime(record.insuranceEndDate, 'yyyy-MM-dd')"
        >
          {{ parseDateTime(record.insuranceEndDate, 'yyyy-MM-dd') }}
        </span>
        <span slot="vehicleBrand" slot-scope="text, record" :title="vehicleBrandFormat(record)">
          {{ vehicleBrandFormat(record) }}
        </span>
        <span slot="vehicleColor" slot-scope="text, record">
          {{ vehicleColorFormat(record) }}
        </span>
        <span slot="driveType" slot-scope="text, record">
          {{ driveTypeFormat(record) }}
        </span>
        <span slot="emissionStandard" slot-scope="text, record">
          {{ emissionStandardFormat(record) }}
        </span>
        <span slot="lease" slot-scope="text, record">
          {{ leaseFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:insurance:list']" v-if="record.lease == 1" />
          <a
            href="javascript:;"
            @click="goToInsurance(record)"
            v-hasPermi="['tenant:iot:lease:list']"
            v-if="record.lease == 1"
          >
            保险记录
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:lease:list']" v-if="record.lease == 1" />
          <a
            href="javascript:;"
            @click="goToLease(record)"
            v-hasPermi="['tenant:iot:lease:list']"
            v-if="record.lease == 1"
          >
            车辆租赁
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:annualReview:list']" v-if="record.lease == 1" />
          <a
            href="javascript:;"
            @click="goToAnnualReview(record)"
            v-hasPermi="['tenant:iot:annualReview:list']"
            v-if="record.lease == 1"
          >
            车辆年审
          </a>

          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleAccount:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:vehicleAccount:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleAccount:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:vehicleAccount:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listVehicleAccount, delVehicleAccount, exportVehicleAccount } from '@/api/iot/vehicleAccount'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'
import { treeselect } from '@/api/system/dept'

export default {
  name: 'VehicleAccount',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    return {
      // 机构树选项
      deptOptions: [
        {
          id: 0,
          label: '',
          children: []
        }
      ],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 车辆大类(字典) 垃圾清运车、道路保洁车字典
      vehicleCategoryOptions: [],
      // 车辆小类-功能(字典) 清扫车、洗地车字典
      vehicleFunctionOptions: [],
      // 是否自动驾驶(字典) 1是、2否字典
      automaticDrivingOptions: [],
      // 车辆品牌(字典) 福龙马牌、中联牌、东风牌、田意小神童、重庆迪马字典
      vehicleBrandOptions: [],
      // 车辆颜色(字典) 1白、2黑、3绿、4黄、5蓝、6红字典
      vehicleColorOptions: [],
      // 强制报废期截止日期时间范围
      daterangeMandatoryScrapDeadlineDate: [],
      // 出厂日期时间范围
      daterangeManufactureDate: [],
      // 驱动形式(字典) 纯电，插电混动，不插电混动，汽油，柴油，燃气字典
      driveTypeOptions: [],
      // 排放标准(字典) 国五、国六字典
      emissionStandardOptions: [],
      // 推荐燃油标号 95#、92#字典
      recommendFuelGradeOptions: [],
      // 是否租赁 1是、2否字典
      leaseOptions: [],
      // 购置时间时间范围
      daterangePurchaseDate: [],
      // 是否删除 0否1是字典
      statusOptions: [],
      // 查询参数
      queryParam: {
        vehicleNo: null,
        licensePlateNum: null,
        vehicleCategory: null,
        vehicleFunction: null,
        automaticDriving: null,
        vehicleBrand: null,
        vehicleModel: null,
        curbWeight: null,
        vehicleColor: null,
        approvedLoad: null,
        surplusDepreciationAmount: null,
        mandatoryScrapDeadlineDate: null,
        certificateKeeperName: null,
        frameNo: null,
        engineNo: null,
        vehiclePhoto: null,
        manufactureDate: null,
        driveType: null,
        enginePower: null,
        soc: null,
        engineDisplacement: null,
        mainEngineBrand: null,
        mainEngineNo: null,
        viceEngineBrand: null,
        viceEngineNo: null,
        chassisBrand: null,
        chassisNo: null,
        tyreNo: null,
        emissionStandard: null,
        recommendFuelGrade: null,
        ownershipName: null,
        lease: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '自编号',
          dataIndex: 'vehicleNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆大类',
          dataIndex: 'vehicleCategory',
          scopedSlots: { customRender: 'vehicleCategory' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '功能',
          dataIndex: 'vehicleFunction',
          scopedSlots: { customRender: 'vehicleFunction' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '自动驾驶',
          dataIndex: 'automaticDriving',
          scopedSlots: { customRender: 'automaticDriving' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆品牌',
          dataIndex: 'vehicleBrand',
          scopedSlots: { customRender: 'vehicleBrand' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆型号',
          dataIndex: 'vehicleModel',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备编号',
          dataIndex: 'deviceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '当前责任人',
          dataIndex: 'employeeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车险到期日期',
          dataIndex: 'insuranceEndDate',
          scopedSlots: { customRender: 'insuranceEndDate' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否租赁',
          dataIndex: 'lease',
          scopedSlots: { customRender: 'lease' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getTreeselect()
    this.getDicts('iot_vehicleAccount_category').then((response) => {
      this.vehicleCategoryOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_function').then((response) => {
      this.vehicleFunctionOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_automaticDriving').then((response) => {
      this.automaticDrivingOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_brand').then((response) => {
      this.vehicleBrandOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_color').then((response) => {
      this.vehicleColorOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_DriveType').then((response) => {
      this.driveTypeOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_emissionStandard').then((response) => {
      this.emissionStandardOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_fuelGrade').then((response) => {
      this.recommendFuelGradeOptions = response.data
    })
    this.getDicts('iot_vehicleAccount_lease').then((response) => {
      this.leaseOptions = response.data
    })
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询车辆台账列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeMandatoryScrapDeadlineDate !== null &&
        this.daterangeMandatoryScrapDeadlineDate !== '' &&
        this.daterangeMandatoryScrapDeadlineDate.length !== 0
      ) {
        this.queryParam.params['beginMandatoryScrapDeadlineDate'] = this.daterangeMandatoryScrapDeadlineDate[0]
        this.queryParam.params['endMandatoryScrapDeadlineDate'] = this.daterangeMandatoryScrapDeadlineDate[1]
      }
      if (
        this.daterangeManufactureDate !== null &&
        this.daterangeManufactureDate !== '' &&
        this.daterangeManufactureDate.length !== 0
      ) {
        this.queryParam.params['beginManufactureDate'] = this.daterangeManufactureDate[0]
        this.queryParam.params['endManufactureDate'] = this.daterangeManufactureDate[1]
      }
      if (
        this.daterangePurchaseDate !== null &&
        this.daterangePurchaseDate !== '' &&
        this.daterangePurchaseDate.length !== 0
      ) {
        this.queryParam.params['beginPurchaseDate'] = this.daterangePurchaseDate[0]
        this.queryParam.params['endPurchaseDate'] = this.daterangePurchaseDate[1]
      }
      listVehicleAccount(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = response.data
      })
    },
    // 车辆大类(字典) 垃圾清运车、道路保洁车字典翻译
    vehicleCategoryFormat(row, column) {
      return this.selectDictLabel(this.vehicleCategoryOptions, row.vehicleCategory)
    },
    // 车辆小类-功能(字典) 清扫车、洗地车字典翻译
    vehicleFunctionFormat(row, column) {
      return this.selectDictLabel(this.vehicleFunctionOptions, row.vehicleFunction)
    },
    // 是否自动驾驶(字典) 1是、2否字典翻译
    automaticDrivingFormat(row, column) {
      return this.selectDictLabel(this.automaticDrivingOptions, row.automaticDriving)
    },
    // 车辆品牌(字典) 福龙马牌、中联牌、东风牌、田意小神童、重庆迪马字典翻译
    vehicleBrandFormat(row, column) {
      return this.selectDictLabel(this.vehicleBrandOptions, row.vehicleBrand)
    },
    // 车辆颜色(字典) 1白、2黑、3绿、4黄、5蓝、6红字典翻译
    vehicleColorFormat(row, column) {
      return this.selectDictLabel(this.vehicleColorOptions, row.vehicleColor)
    },
    // 驱动形式(字典) 纯电，插电混动，不插电混动，汽油，柴油，燃气字典翻译
    driveTypeFormat(row, column) {
      return this.selectDictLabel(this.driveTypeOptions, row.driveType)
    },
    // 排放标准(字典) 国五、国六字典翻译
    emissionStandardFormat(row, column) {
      return this.selectDictLabel(this.emissionStandardOptions, row.emissionStandard)
    },
    // 推荐燃油标号 95#、92#字典翻译
    recommendFuelGradeFormat(row, column) {
      return this.selectDictLabel(this.recommendFuelGradeOptions, row.recommendFuelGrade)
    },
    // 是否租赁 1是、2否字典翻译
    leaseFormat(row, column) {
      return this.selectDictLabel(this.leaseOptions, row.lease)
    },
    // 是否删除 0否1是字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeMandatoryScrapDeadlineDate = []
      this.daterangeManufactureDate = []
      this.daterangePurchaseDate = []
      this.queryParam = {
        vehicleNo: undefined,
        licensePlateNum: undefined,
        vehicleCategory: undefined,
        vehicleFunction: undefined,
        automaticDriving: undefined,
        vehicleBrand: undefined,
        vehicleModel: undefined,
        curbWeight: undefined,
        vehicleColor: undefined,
        approvedLoad: undefined,
        surplusDepreciationAmount: undefined,
        certificateKeeperName: undefined,
        frameNo: undefined,
        engineNo: undefined,
        vehiclePhoto: undefined,
        driveType: undefined,
        enginePower: undefined,
        soc: undefined,
        engineDisplacement: undefined,
        mainEngineBrand: undefined,
        mainEngineNo: undefined,
        viceEngineBrand: undefined,
        viceEngineNo: undefined,
        chassisBrand: undefined,
        chassisNo: undefined,
        tyreNo: undefined,
        emissionStandard: undefined,
        recommendFuelGrade: undefined,
        ownershipName: undefined,
        lease: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleAccount(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleAccount(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /**
     * 跳转保险管理
     */
    goToInsurance(record) {
      this.$router.push('/account/insurance?vehicleNo=' + record.vehicleNo)
    },
    /**
     * 跳转车辆租赁
     */
    goToLease(record) {
      this.$router.push('/account/lease?vehicleNo=' + record.vehicleNo + '&vehicleAccountId=' + record.id)
    },
    /**
     * 跳转车辆年审
     */
    goToAnnualReview(record) {
      this.$router.push('/account/annualReview?vehicleNo=' + record.vehicleNo)
    }
  }
}
</script>
